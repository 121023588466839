<template xmlns="http://www.w3.org/1999/html">
  <div class="item">

    <div class="item-content">

      <div class="receiver">
        {{ phone }} 收
      </div>
      <div class="sms-content van-multi-ellipsis--l2">

      {{content}}
      </div>

        <div class="reply-count item-bottom">收到{{replyCount}}条回复</div>
        <div class="send-time item-bottom">{{getDate()}}</div>
    </div>
    <div v-if="smsStatus === 0" class="icon"/>
  </div>

</template>

<script>
import dayjs from "dayjs";
export default {
  name: "MailboxItem",
  props: {
    date: String,
    content: String,
    phone: String,
    replyCount: Number,
    smsStatus: Number,
  },
  methods:{
    getDate(){
      return dayjs(this.date).format("YYYY/MM/DD HH:mm")
    }
  }

}
</script>

<style scoped>
.date{
  margin-top: 8px;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  color: #9ca3af;
}
.item{
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  font-size: 12px;
  color: #292833;
  box-sizing: border-box;
  word-wrap:break-word;
  overflow: hidden;
  position: relative;
  word-break: break-all;
  margin-top: 10px;
  width: 345px;
  height: 110px;
}
.icon{
  width: 64px;
  height: 64px;
  right: 10px;
  position: absolute;
  z-index: 2;
  background: url('../../assets/image/arrived.png') no-repeat;
  top:20px;
  background-size: 64px 64px;
}
.item-content{
  display: flex;
  flex-direction: column;
}
.receiver{
  color: #9ca3af;
  font-size: 12px;
  height: 20px;
  line-height: 20px;
}
.sms-content{
  color: #292833;

  margin-top: 8px;
  font-size: 14px;
}
.item-bottom{
  font-size: 10px;
  color: #9ca3af;
  height: 20px;

}
.reply-count{
  line-height: 20px;
  position: absolute;
  bottom: 6px;
}
.send-time{
  line-height: 20px;
  position: absolute;
  bottom: 6px;
  right: 10px;
}
</style>