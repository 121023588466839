<template>

  <div class="main">
    <van-nav-bar title="编写短信"
                 left-arrow
                 @click-left="onClickLeft"/>

    <van-form class="form" @submit="submit">

      <van-cell-group class="item" inset>
        <van-field v-model="phone" type="tel"
                   placeholder="请输入对方手机号"
                   clearable
                   :rules="[{ pattern, message: '请输入正确的手机号' }]"/>
      </van-cell-group>

      <van-cell-group class="item" inset>
        <van-field v-model="sendTime" @click="showTimePopup" placeholder="预约发送时间" right-icon="arrow" readonly
                   :rules="[{ required: true, message: '请选择短信发送时间' }]"/>

        <van-popup v-model:show="show" position="bottom">
          <van-datetime-picker
              v-model="currentDate"
              type="datetime"
              title="请选择短信发送时间"
              :min-date="minDate"
              @cancel="show = false"
              @confirm="submitTime"
          />
        </van-popup>
      </van-cell-group>
      <van-cell-group class="item" inset>
        <van-field v-model="smsContent" type="textarea" rows="6" maxlength="150"
                   show-word-limit
                   placeholder="点击输入短信内容（情感、表白、早晚安、道歉、 祝福…）禁止发送包含辱骂、破环他人关系等违规信息"
                   class="sms-content"
                   :rules="[{ required: true, message: '请填写短信内容' }]"
                   clearable/>
      </van-cell-group>
      <div class="form-item anonymous-line">
        <van-checkbox v-model="anonymous" checked-color="#00D477">
          匿名发送
        </van-checkbox>
        <!--        <input class="name-input " placeholder="请输入你的称呼"/>-->
        <div v-if="!anonymous" class="van-hairline--bottom name-input">
          <van-field class="name-input-field" v-model="name" placeholder="请输入你的称呼"/>
        </div>
        <!--        <van-field class="name-input" v-model="name" placeholder="请输入你的称呼"/>-->
      </div>

      <div class="center">

        <van-button class="submit-btn" round block type="primary" color="#00D477" native-type="submit">
          发送
        </van-button>
      </div>

    </van-form>
    <div class="preview-sms" @click="preview">
      预览短信
    </div>
    <div class="sms-price-explain">
      短信发送价格：50字/元
    </div>
    <van-popup v-model:show="previewSms" :style="{ height: '40%' }" style="background-color: #f7f7f7" closeable
               position="bottom">
      <div class="center-column">
        <div class="preview-date" style="color: #999">
          {{ previewDate }}
        </div>
        <div class="preview-box">
          <div class="preview-box-from">
            10691828543590
          </div>
          <div class="preview-box-item">
            {{ smsContent }}
          </div>
          <div class="preview-box-item" style="width: 100%; text-align: right!important;">
            {{ name }}
          </div>
        </div>
      </div>
    </van-popup>
    <van-dialog v-model:show="showBindPhoneDialog" :before-close="beforeClose" title="绑定手机号"
                show-cancel-button >
      <div class="phone-tips">短信发送成功，绑定手机号可及时收到回复提醒</div>
      <van-form>
        <van-cell-group inset>
          <van-field
              v-model="form.phone"
              label="手机号"
              placeholder="请输入手机号"
              type="tel"
              clearable
          />
          <van-field
              v-model="form.code"
              class="code"
              center
              clearable
              type="tel"
              label="验证码"
              placeholder="请输入短信验证码"
          >
            <template #button>
              <van-button size="small" class="get-code" type="primary" color="#00D477" @click.prevent="sendSmsCode"
                          :disabled="isSmsSend">{{ sendBtnText }}
              </van-button>
            </template>
          </van-field>
        </van-cell-group>

      </van-form>
    </van-dialog>

  </div>

</template>

<script>
import {NavBar, Form, Field, CellGroup, Popup, DatetimePicker, Button, Checkbox, Dialog, Toast} from 'vant';
import dayjs from 'dayjs'
import {createOneYuanOrder, createSmsOrder} from "../api/order";
import {createSms} from "../api/sms";
import wx from "weixin-js-sdk";
import {getJsAPiSign, getUserInfo, bindPhone, getCode} from "../api/user";

export default {
  name: "write",
  setup() {
    const pattern = /1[3-9]\d{9}$/;
    return {
      pattern,
    };
  },
  data() {
    return {
      phone: '',
      smsContent: '',
      smsDate: '',
      show: false,
      anonymous: true,
      sendTime: '',
      orderId: '',
      currentDate: new Date(),
      // minDate: new Date(),
      minDate: dayjs().add(2, 'm').toDate(),
      name: '',
      previewSms: false,
      previewDate: '',
      isPay: false,
      isBindPhone: true,
      showBindPhoneDialog: false,
      form: {
        phone: '',
        code: ''
      },
      // 是否已经发送了验证码
      isSmsSend: false,
      sendBtnText: '发送验证码',
      timer: null,
      counter: 60,
      pattern: /1[3-9]\d{9}$/,

    }
  },

  created() {
    let url = {'url': window.location.href}
    getJsAPiSign(url)
        .then(res => {
          wx.config({
            debug: false, // 开启调试模式,
            appId: res.data.data.appId, // 必填，企业号的唯一标识，此处填写企业号corpid
            timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.data.signature,// 必填，签名，见附录1
            jsApiList: ['chooseWXPay'] // 必填，需要使用的JS接口列表，所有JS接口列
          });
        })
    getUserInfo().then(res => {

      // if (res.data.code === '00000') {
      //   if (res.data.data.phone != null) {
      //     this.isBindPhone = true
      //   } else {
      //     this.isBindPhone = false
      //   }
      // }
      // console.log(res.data.data)
    })
  },
  beforeDestroy() {
    Toast.clear()
  },


  methods: {
    onClickLeft() {
      this.$router.push({
        path: "/index"
      })
    },
    submit() {
      if (!this.isBindPhone){
        this.showBindPhoneDialog = true
        return
      }
      if (this.isPay) {
        Toast.loading({forbidClick: true, duration: 0})
        this.commitSms()
      } else {
        this.createOrder()
      }


    },
    createOrder() {
      createSmsOrder(this.smsContent)
          .then(res => {
            if (res.data.code === '00000') {
              this.orderId = res.data.data.orderId
              this.pay(res.data.data)
            }
          })
    },
    commitSms() {
      let form = {
        orderId: this.orderId,
        phone: this.phone,
        content: this.smsContent,
        sendTime: this.sendTime,
        sendName: this.name,
      }

      createSms(form).then(res => {
        Toast.clear()
        if (res.data.code === '00000') {
          this.isPay = false
          if (!this.isBindPhone) {
            // this.showBindPhone()
            this.showBindPhoneDialog = true
          } else {
            Toast.success({
              message: '创建成功\n\n关注短信传情公众号能及时获取短信回复通知哦',
              duration: 3000
            })
          }
        } else if (res.data.code === '00017') {
          this.isPay = false
          this.createOrder()
        } else {
          Toast.fail("创建失败\n如成功支付会在3个工作日内退回")
        }
      })
    },
    toBindPhone() {
      if (this.form.code.length !== 6) {
        Toast('请输入验证码')
        return
      }
      bindPhone(this.form).then(res => {
        if (res.data.code === '00000') {
          this.showBindPhoneDialog = false
          this.isBindPhone = true
          Toast("绑定成功")
        } else if (res.data.code === '00007') {
          Toast("验证码错误")
        } else {
          Toast("绑定失败")
        }
      })
    },
    cancelBindPhone() {
      this.showBindPhoneDialog = false
    },
    submitTime(dateTime) {
      this.currentDate = dateTime;

      this.sendTime = dayjs(dateTime).format("YYYY-MM-DD HH:mm");
      this.show = false;
    },
    showTimePopup() {
      this.show = true;
    },
    preview() {
      this.previewSms = true;
      this.previewDate = dayjs(new Date()).format("YYYY年MM月DD日 HH:mm")
    },
    pay(data) {
      let that = this
      wx.chooseWXPay({
        timestamp: data.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
        nonceStr: data.nonceStr, // 支付签名随机串，不长于 32 位
        package: data.packageValue, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
        signType: data.signType, // 签名方式,必须跟统一下单接口签名方式一致
        paySign: data.paySign, // 支付签名
        success: function (res) {
          if (res.errMsg == "chooseWXPay:ok") {
            //支付成功后跳转的页面
            console.log('支付成功')
            that.isPay = true
            that.commitSms()
            Toast.loading({forbidClick: true, duration: 0})
          }
        },
        // 支付取消回调函数
        cencel: function (res) {
          that.isPay = false
          console.log('支付取消')
        },
        // 支付失败回调函数
        fail: function (res) {
          that.isPay = false
          console.log('支付失败')
        }
      })
    },
    /**
     * 重置倒计时
     */
    reset() {
      this.isSmsSend = false
      this.sendBtnText = '发送验证码'
      if (this.timer) {
        clearInterval(this.timer)
        this.counter = 60
        this.timer = null
      }
    },
    /**
     * 倒计时
     */
    countDown() {
      this.timer = setInterval(() => {
        this.sendBtnText = `${this.counter}秒`
        this.counter--
        if (this.counter < 0) {
          this.reset()
        }
      }, 1000)
    },
    /**
     * 发送验证码
     */
    sendSmsCode() {
      // 判断手机号是否已经输入

      if (!this.form.phone) {
        Toast('请输入手机号')
        return false
      }
      // 判断手机号是否符合要求
      console.log(this.pattern.test(this.form.phone))
      if (!this.pattern.test(this.form.phone)) {
        Toast('请输入正确的手机号码')
        return false
      }
      // 调用接口，发送短信验证码
      this.isSmsSend = true
      // 开始倒计时，60s之后才能再次点击
      this.countDown()
      console.log(this.form.phone)
      let tel = {'phone': this.form.phone}
      getCode(tel).then(res => {
        if (res.data.code === '00000') {
          Toast("发送成功，验证码5分钟内有效")
        } else {
          Toast("获取失败")
          this.reset()
        }
      })
    },
    async beforeClose(action) {
      return new Promise(async (resolve) => {
        if (action === 'confirm') {
          if (this.form.code.length !== 6) {
            Toast('请输入验证码')
            resolve(false);
            return
          }
          await bindPhone(this.form).then(res => {
            if (res.data.code === '00000') {
              this.showBindPhoneDialog = false
              this.isBindPhone = true
              Toast("绑定成功")
            } else if (res.data.code === '00007') {
              Toast("验证码错误")
              resolve(false);

            } else {
              Toast("绑定失败")
              resolve(false);

            }
          }).catch(res=>{
            Toast("绑定失败")
            resolve(false);
          })
          resolve(true);
        } else {
          resolve(true);
        }
      });
    },
  },
  components: {
    [NavBar.name]: NavBar,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Form.name]: Form,
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [Toast.name]: Toast,
    [Dialog.Component.name]: Dialog.Component,
  }
}
</script>

<style scoped>
.van-nav-bar {
  --van-nav-bar-icon-color: #292833;
  --van-nav-bar-text-color: #292833;
  background-color: #f7f7f7;
}

.main {
  height: 100vh;
  background-color: #f7f7f7;
}


.item {
  margin-top: 15px;
  box-sizing: border-box;
}

.form-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
  margin-top: 15px;
  background-color: #f7f7f7;
}

.anonymous-line {
  min-height: 45px;
}

.name-input {
  width: 150px;
}

.name-input-field {
  background-color: #f7f7f7;
}

.sms-content {
  min-height: 180px;
  background: url('../assets/image/02.png') right 15px bottom 15px no-repeat;
  background-size: 120px 120px;
}

.submit-btn {
  align-items: center;
  width: 160px;
}

.center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 15px;
  padding-top: 15px;
  background-color: #f7f7f7;
  box-sizing: border-box;
}

.center-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 15px;
  padding-top: 15px;
  padding-right: 15px;
  background-color: #f7f7f7;
}

.preview-sms {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #5c678d;
  font-size: 14px;
  height: 44px;
  background-color: #f7f7f7;
}

.sms-price-explain {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #999999;
  font-size: 12px;
  height: 30px;
  background-color: #f7f7f7;
}

.preview-date {
  font-size: 14px;
}

.preview-box {
  min-height: 80px;
  background-color: white;
  border-radius: 15px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 15px;
}

.preview-box-from {
  color: #e6e6e6;
  font-size: 16px;
}

.preview-box-item {
  color: #292833;
  line-height: 20px;
  margin-top: 10px;
  font-size: 14px;
}

.phone-tips {
  font-size: 13px;
  margin: 15px;
  color: #9ca3af;
  /*margin-right: 15px;*/
  /*margin-left: 15px;*/
}
</style>