<template>
  <div>

    <div class="time">
      {{ getDate() }}
    </div>
    <div class="content">
      {{ smsContent }}
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "ChatDetailsRightItem",
  props: {
    date: String,
    smsContent: String,
  },
  methods: {
    getDate() {
      return dayjs(this.date).format("YYYY/MM/DD HH:mm")
    }
  }
}
</script>

<style scoped>
.time {
  height: 30px;
  font-size: 12px;
  color: #9ca3af;
  line-height: 30px;
  width: 100%;

  margin-top: 10px;
  text-align: center
}

.content {
  word-break: break-all;
  word-wrap: break-word;
  max-width: 290px;
  float: left;
  padding: 12px;
  font-size: 16px;
  line-height: 24px;
  color: #292833;
  border-radius: 10px;
  background: white;
}

.content1 {
  word-break: break-all;
  word-wrap: break-word;
  max-width: 290px;
  width: auto;
  padding: 12px;
  font-size: 16px;
  line-height: 24px;
  color: #292833;
  float: right;
  border-radius: 10px;
  background: #a9ea7a;
}
</style>