<template>
  <div class="main  van-safe-area-bottom">
    <van-nav-bar :title="phone"
                 left-arrow
                 safe-area-inset-top
                 fixed
                 style="z-index: 99;"
                 @click-left="onClickLeft"/>

    <div class="content">
      <div v-for="(item,index) in chatList">
        <div v-if="item.replyType === 0">
          <ChatDetailsLeftItem
              :date="item.sendTime"
              :smsContent="item.content"/>
        </div>
        <div v-else>
          <ChatDetailsRightItem
              :date="item.sendTime"
              :smsContent="item.content"/>
        </div>
      </div>

    </div>
    <div class="bottom">
      <div class="sms-input">
        <van-cell-group inset>
          <van-field v-model="smsContent" placeholder="输入内容回复"/>
        </van-cell-group>
      </div>

      <img class="img" @click="send" src="@/assets/image/sms-send.png">
    </div>

  </div>


</template>

<script>
import {NavBar, Field, CellGroup, Toast} from 'vant';
import ChatDetailsLeftItem from "./chat/ChatDetailsLeftItem";
import ChatDetailsRightItem from "./chat/ChatDetailsRightItem";
import {getSmsChatList} from "../api/sms";
import {createOneYuanOrder} from "../api/order";
import {sendSms} from "../api/sms";
import wx from "weixin-js-sdk";
import {getJsAPiSign,getUserInfo} from "../api/user";

export default {
  name: "Chat",
  components: {
    [NavBar.name]: NavBar,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    ChatDetailsLeftItem,
    ChatDetailsRightItem,
  },
  created() {
    this.id = this.$route.query.id;
    this.phone = this.$route.query.phone;
    this.getData();

    let url = {'url': window.location.href}
    getJsAPiSign(url)
        .then(res => {
          wx.config({
            debug: false, // 开启调试模式,
            appId: res.data.data.appId, // 必填，企业号的唯一标识，此处填写企业号corpid
            timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.data.signature,// 必填，签名，见附录1
            jsApiList: ['chooseWXPay'] // 必填，需要使用的JS接口列表，所有JS接口列
          });
        })
  },
  beforeDestroy() {
    Toast.clear()
  },
  data() {
    return {
      id: '',
      phone: '',
      smsContent: '',
      orderId: '',
      chatList: [],
      isPay: false,
      isBindPhone: false
    }
  },

  methods: {
    onClickLeft() {
      this.$router.push({
        path: "/mailbox"
      })
    },
    getData() {

      getSmsChatList(this.id).then(res => {
        // console.log(res.data)
        this.chatList = res.data.data
      })
      // console.log("id:"+this.id+",phone:"+this.phone)
    },
    send() {
      if (this.smsContent.length < 1) {
        Toast.fail("请先输入内容")
        return
      }
      if (this.isPay) {
        Toast.loading({forbidClick: true, duration: 0})
        this.commitSms()
      } else {
        this.createOrder()
      }

    },
    createOrder(){
      createOneYuanOrder()
          .then(res => {
            if (res.data.code === '00000') {
              this.orderId = res.data.data.orderId

              this.pay(res.data.data)
            }
          })
    },
    pay(data) {
      let that = this
      wx.chooseWXPay({
        timestamp: data.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
        nonceStr: data.nonceStr, // 支付签名随机串，不长于 32 位
        package: data.packageValue, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
        signType: data.signType, // 签名方式,必须跟统一下单接口签名方式一致
        paySign: data.paySign, // 支付签名
        success: function (res) {
          if (res.errMsg == "chooseWXPay:ok") {
            //支付成功后跳转的页面
            console.log('支付成功')
            that.isPay = true
            that.commitSms()
            Toast.loading({forbidClick: true, duration: 0})
          }
        },
        // 支付取消回调函数
        cencel: function (res) {
          that.isPay = false
          console.log('支付取消')
        },
        // 支付失败回调函数
        fail: function (res) {
          that.isPay = false
          console.log('支付失败')
        }
      })
    },
    commitSms() {
      let form = {
        orderId: this.orderId,
        chatId: this.id,
        content: this.smsContent,
      }

      sendSms(form).then(res => {
        Toast.clear()
        if (res.data.code === '00000') {
          this.smsContent = ''
          Toast.success({
            message:'创建成功\n\n关注公众号才能及时获取短信回复通知哦',
            duration: 3000
          })
          this.getData();
          this.isPay = false
        } else if (res.data.code === '00017'){
          this.isPay = false
          this.createOrder()
        }else {
          Toast.fail("创建失败\n如成功支付会在3个工作日内退回")
        }
        // console.log(res.data)
      })
    }
  }
}
</script>

<style scoped>
.van-nav-bar {
  --van-nav-bar-icon-color: #292833;
  --van-nav-bar-text-color: #292833;
  background-color: #f7f7f7;
}

.main {
  height: 100vh;
  background-color: #f7f7f7;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 48px 15px 15px;
  box-sizing: border-box;
  flex: 1;
  overflow-y: auto;
  background-color: #f7f7f7;
}

.bottom {
  float: bottom;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  height: 60px;
  align-items: center;
  box-sizing: border-box;
}

.sms-input {
  flex: 1;
}

.img {
  height: 40px;
  width: 40px;
  margin-right: 10px;
}


</style>