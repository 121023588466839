<template>

</template>

<script>
import {Loading, Toast} from 'vant';
import {getUserInfo, login} from "../api/user";

export default {
  name: "Login",
  components: {
    [Loading.name]: Loading,
    [Toast.name]: Toast,
  },
  data() {
    return {
      loginForm: {
        code: '',
        channel: '',
      },
      backUrl: ''
    }
  },
  created() {
    let code = this.getUrlParam("code")

    // this.backUrl = this.$store.getters.getBackUrl
    // console.log("code-->"+code)
    let local = window.location.href
    this.loginForm.code = code
    this.loginForm.channel = this.$store.getters.getChannel
    if (code === null || code === '') {
      // let returnUrl = window.location.href
      // getAuthUrl(returnUrl)
      // .then(res =>{
      //   window.location.href = res.data.data
      // })
      window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx79039ca71d14ddaf&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope=snsapi_base&state=state#wechat_redirect'
    } else {
      Toast.loading({forbidClick: true, duration: 0})
      // console.log(this.loginForm)
      login(this.loginForm)
          .then(rsp => {
            if (rsp.data.code === '00000') {
              this.$store.commit('set_token', rsp.data.data)
              getUserInfo()
                  .then(response => {
                    // console.log(this.backUrl)
                    if (response.data.code === '00000') {
                      this.$store.commit('setUserInfo', response.data.data)
                      // this.$router.push({path:this.$store.getters.getBackUrl})
                      Toast.clear()
                      window.location.href = this.$store.getters.getBackUrl
                    }
                  })
            } else {
              Toast.clear()
              window.location.href = this.$store.getters.getBackUrl
              // window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx79039ca71d14ddaf&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope=snsapi_base&state=state#wechat_redirect'
            }
          })
    }
  },
  methods: {
    getUrlParam(name) {
      const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
      let url = window.location.href.split('#')[0]
      let search = url.split('?')[1]
      if (search) {
        const r = search.substr(0).match(reg)
        if (r !== null) return unescape(r[2])
        return null
      } else {
        return null
      }
    }
  }
}
</script>

<style scoped>

</style>