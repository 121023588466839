import request from '@/utils/request'
import qs from 'qs'

export function getAuthUrl(data) {
    return request({
        url: '/api/wx/mp/wx79039ca71d14ddaf/auth',
        method: 'get',
        params:{
            returnUrl: data,
            state: 'state'
        }
        // data: {
        //     returnUrl: data
        // }
    })
}
export function getJsAPiSign(data) {
    return request({
        url: '/api/wx/mp/wx79039ca71d14ddaf/getSign',
        method: 'post',
        headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            },
        data,
    })
}
export function getCode(data) {
    return request({
        url: '/api/common/code',
        method: 'post',
        headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            },
        data,
    })
}
export function login(data) {
    return request({
        url: '/api/wx/wx79039ca71d14ddaf/mp/login',
        method: 'get',
        params:{
            code:data.code,
            channel:data.channel
        }
    })
}
export function getUserInfo() {
    return request({
        url: '/api/userinfo',
        method: 'get',
    })
}
export function bindPhone(data) {
    return request({
        url: '/api/userinfo/bindPhone',
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        data,
    })
}
export function addReceiver(data) {
    return request({
        url: '/api/profitSharing/receiver/add',
        method: 'post',
        data
    })
}
export function getHomeBanner() {
    return request({
        url: '/api/banner/list',
        method: 'post',
        data: {
            appid: 'wx79039ca71d14ddaf',
            groupId: 2
        }
    })
}