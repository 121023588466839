import {createStore} from 'vuex'
import storage from "../utils/storage";
import dayjs from "dayjs";

export default createStore({
    state: {
        token: '',
        expressData: '',
        user: null,
        backUrl: '',
        channel: ''
    },
    getters: {
        get_token(state) {
            let token = state.token || storage.get("token") || null;
            if (null === token) {
                return token;
            }
            let ed = state.expressData || storage.get("tokenExpressDate") || null;
            if (null === ed) {
                return null;
            }
            if (dayjs().unix() < ed) {
                return token;
            } else {
                return null;
            }
        },
        getTokenExpressData(state) {
            let ed = state.expressData || storage.get("tokenExpressDate") || null;
            if (null === ed) {
                return null;
            } else {
                return dayjs().unix() < ed;
            }
        },
        getUserInfo(state){
            return state.user|| storage.get("user") || null;
        },
        getBackUrl(state){
            return state.backUrl || storage.get("backUrl") || null;
        },
        getChannel(state){
            return state.channel || storage.get("channel") || null;
        }
    },
    // 计算属性
    mutations: {
        // 修改token，并将token存入localStorage
        set_token(state, token) {
            state.token = token;
            storage.set('token', token);
            storage.set('tokenExpressDate', dayjs().add(30, 'minute').unix())
            // console.log('store、localstorage保存token成功！');
            // console.log('token过期时间' + dayjs().add(30, 'minute').unix());
        },
        del_token(state) {
            state.token = "";
            state.expressData = "";
            storage.remove("token");
            storage.remove("tokenExpressDate");
        },
        // 可选
        setUserInfo(state, user) {
            state.user = user;
            storage.set('user', user);
        },
        setBackUrl(state, backUrl) {
            state.backUrl = backUrl;
            storage.set('backUrl', backUrl);
        },
        setChannel(state, channel) {
            state.channel = channel;
            storage.set('channel', channel);
        }
    },
    actions: {},
    modules: {}
})
