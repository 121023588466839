<template>
  <div class="main">
    <div class="title">
      分销商入驻申请
    </div>
    <van-form class="form">
      <van-cell-group inset>
        <van-field
            v-model="form.name"
            label="真实姓名"
            placeholder="请输入当前微信绑定的名字"
            clearable
            maxlength="11"
        />
        <van-field
            v-model="form.phone"
            label="手机号"
            placeholder="请输入手机号"
            type="tel"
            clearable
        />
        <van-field
            v-model="form.code"
            class="code"
            center
            clearable
            type="tel"
            label="验证码"
            placeholder="请输入短信验证码"
        >
          <template #button>
            <!--            <van-button size="small" color="#00D477" type="primary">发送验证码</van-button>-->
            <van-button size="small" class="get-code" type="primary" color="#00D477" @click.prevent="sendSmsCode"
                        :disabled="isSmsSend">{{ sendBtnText }}
            </van-button>
          </template>
        </van-field>
      </van-cell-group>

    </van-form>
    <van-button class="submit-btn" round block type="primary" @click="submitReceiver" color="#00D477">提交</van-button>

  </div>
</template>

<script>
import {CountDown, Field, CellGroup, Toast, Form, Button, Dialog} from 'vant';
import {getCode, addReceiver} from "../api/user";

export default {
  name: "Receiver",
  data() {
    return {
      form: {
        name: '',
        phone: '',
        code: ''
      },
      // 是否已经发送了验证码
      isSmsSend: false,
      sendBtnText: '发送验证码',
      timer: null,
      counter: 60,
      pattern: /1[3-9]\d{9}$/
    }
  },
  methods: {
    /**
     * 重置倒计时
     */
    reset() {
      this.isSmsSend = false
      this.sendBtnText = '发送验证码'
      if (this.timer) {
        clearInterval(this.timer)
        this.counter = 60
        this.timer = null
      }
    },
    /**
     * 倒计时
     */
    countDown() {
      this.timer = setInterval(() => {
        this.sendBtnText = `${this.counter}秒`
        this.counter--
        if (this.counter < 0) {
          this.reset()
        }
      }, 1000)
    },
    /**
     * 发送验证码
     */
    sendSmsCode() {
      // 判断手机号是否已经输入

      if (!this.form.phone) {
        Toast('请输入手机号')
        return false
      }
      // 判断手机号是否符合要求
      console.log(this.pattern.test(this.form.phone))
      if (!this.pattern.test(this.form.phone)) {
        Toast('请输入正确的手机号码')
        return false
      }
      // 调用接口，发送短信验证码
      this.isSmsSend = true
      // 开始倒计时，60s之后才能再次点击
      this.countDown()
      console.log(this.form.phone)
      let tel = {'phone': this.form.phone}
      getCode(tel).then(res => {
        if (res.data.code === '00000') {
          Toast("发送成功，验证码5分钟内有效")
        } else {
          Toast("获取失败")
          this.reset()
        }
      })
    },
    submitReceiver() {
      if (!this.form.name) {
        Toast('请输入名字')
        return false
      }
      if (this.form.code.length !== 6) {
        Toast('请输入验证码')
        return false
      }
      addReceiver(this.form).then(res => {
        if (res.data.code === '00000') {
          Dialog.alert({
            message: '已成功提交，请联系管理员给你专属邀请链接',
            confirmButtonColor: '#00D477'
          })
        } else {
          Toast("提交失败")
        }
      })

    }
  },
  components: {
    [CountDown.name]: CountDown,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Toast.name]: Toast,
    [Form.name]: Form,
    [Button.name]: Button,
    [Dialog.Component.name]: Dialog.Component,
  }
}
</script>

<style scoped>
.main {
  height: 100vh;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  height: 100px;
  line-height: 100px;
  color: #292833;
  font-size: 26px;
}

.code {
  width: 340px;
}

.get-code {
  width: 80px;
}

.submit-btn {
  width: 200px;
  margin-top: 20px;
}
</style>