<template>
  <div class="main">
    <van-nav-bar title="创建短信计划"
                 left-arrow
                 @click-left="onClickLeft"/>

    <van-form class="form" @submit="submit">

      <van-cell-group class="item" inset>
        <van-field v-model="planName"
                   placeholder="请输入计划名称"
                   clearable
                   :rules="[{ required:true, message: '请输入计划名称' }]"/>
      </van-cell-group>
      <div class="send-line">
        <div class="send-person">
          <van-field
              class="border-8"
              v-model="toPeople"
              readonly
              @click="showAddresseeTypePop = true"
              right-icon="arrow"
          />
        </div>
        <div style="flex: 1;">
          <van-field v-model="phone"
                     class="border-8"
                     placeholder="请输入手机号"
                     clearable
                     type="tel"
                     :rules="[{ pattern, message: '请输入正确的手机号' }]"/>
        </div>

      </div>

      <div class="send-line">
        <div class="width-165" @click="showStartDatePop = true">
          <van-field
              class="border-8"
              v-model="startDate"
              right-icon="arrow"
              readonly
              placeholder="发送开始日期"
              :rules="[{ required:true, message: '请选择发送开始日期' }]"
          />
        </div>
        <div style="flex: 1"/>
        <div class="width-165" @click="showSmsCountPop = true">
          <van-field v-model="smsCountFormat"
                     class="border-8"
                     placeholder="选择发送天数"
                     clearable
                     readonly
                     right-icon="arrow"
                     :rules="[{ required:true, message: '请选择发送天数' }]"/>
        </div>

      </div>

      <van-cell-group class="item" inset>
        <van-field v-model="sendTimeFormat" @click="showSendTimePop = true" placeholder="请选择发送时间" right-icon="arrow"
                   readonly
                   :rules="[{ required: true, message: '请选择发送时间' }]"/>

      </van-cell-group>
      <van-popup v-model:show="showSendTimePop" position="bottom">
        <van-datetime-picker
            v-model="sendTime"
            type="time"
            title="请选择短信发送时间"
            :min-hour="minHour"
            :max-hour="maxHour"
            @cancel="showSendTimePop = false"
            @confirm="submitTime"
        />
      </van-popup>

      <div class="form-item anonymous-line">
        <van-checkbox v-model="anonymous" checked-color="#00D477">
          匿名发送
        </van-checkbox>
        <div v-if="!anonymous" class="van-hairline--bottom name-input">
          <van-field class="name-input-field" v-model="name" placeholder="请输入你的称呼"/>
        </div>
      </div>

      <div class="center">

        <van-button class="submit-btn" round block type="primary" color="#00D477" native-type="submit">
          创建
        </van-button>
      </div>

    </van-form>
    <div class="sms-count-explain">
      短信发送量总计：{{ smsCount }}条
    </div>

    <van-popup v-model:show="showSmsCountPop" round style="background-color: white"
               position="bottom">
      <van-picker
          :columns="smsCountColumns"
          @confirm="submitSmsCount"
          @cancel="showSmsCountPop = false"/>
    </van-popup>
    <van-popup v-model:show="showAddresseeTypePop" round style="background-color: white"
               position="bottom">
      <van-picker
          :columns="addresseeTypeData"
          @confirm="submitAddressee"
          @cancel="showAddresseeTypePop = false"/>
    </van-popup>
    <van-calendar v-model:show="showStartDatePop" title="请选择发送开始日期" color="#00d477" @confirm="chooseStartDate"/>

  </div>
  <van-dialog/>
</template>

<script>
import {
  NavBar,
  Icon,
  Dialog,
  Form,
  Field,
  CellGroup,
  Popup,
  DatetimePicker,
  Button,
  Checkbox,
  Calendar,
  Picker, Toast
} from 'vant';
import ComboRechargeItem from "../../components/ComboRechargeItem";

import {getPlanTemplateInfo,  createPlanByOrderId} from "../../api/sms";
import {createPlanOrder} from "../../api/order";
import {getJsAPiSign} from "../../api/user";
import dayjs from 'dayjs'
import wx from 'weixin-js-sdk'


export default {
  name: "CreateV2",
  components: {
    [NavBar.name]: NavBar,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Form.name]: Form,
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [Calendar.name]: Calendar,
    [Picker.name]: Picker,
    [Icon.name]: Icon,
    [Toast.name]: Toast,
    ComboRechargeItem,
    [Dialog.Component.name]: Dialog.Component,
  },
  setup() {
    const pattern = /1[3-9]\d{9}$/;
    return {
      pattern,
    };
  },
  data() {
    return {
      id: '',
      planName: '',
      phone: '',
      toPeople: '发给别人',
      startDate: '',
      addresseeType: 'friend',
      addresseeTypeData: ['发给别人', '发给自己'],
      addresseeTypeDataValue: ['friend', 'self'],
      smsCount: '0',
      smsCountFormat: '',
      sendTime: '',
      sendTimeFormat: '',
      showStartDatePop: false,
      showSendTimePop: false,
      showSmsCountPop: false,
      showAddresseeTypePop: false,
      smsCountColumns: ["7天","14天","30天"],
      smsCountColumnsData: [7,14,30],
      smsDate: '',
      anonymous: true,
      currentDate: new Date(),
      minDate: new Date(),
      name: '',
      previewSms: false,
      previewDate: '',
      overChance: '0',
      minHour: '0',
      maxHour: '23',
      planTemplateInfo: null,
      orderId: null,
      isPay: false
    }
  },
  created() {
    this.planName = this.$route.query.name
    this.id = this.$route.query.id
    let url = {'url': window.location.href}
    getJsAPiSign(url)
        .then(res => {
          wx.config({
            debug: false, // 开启调试模式,
            appId: res.data.data.appId, // 必填，企业号的唯一标识，此处填写企业号corpid
            timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.data.signature,// 必填，签名，见附录1
            jsApiList: ['chooseWXPay'] // 必填，需要使用的JS接口列表，所有JS接口列
          });
        })
    getPlanTemplateInfo(this.id)
        .then(res => {
          if (res.data.code === '00000') {
            this.planTemplateInfo = res.data.data
            let hourRange = res.data.data.hourRange
            if (hourRange) {
              this.minHour = hourRange.split("-")[0]
              this.maxHour = hourRange.split("-")[1] - 1
            }
          }
        })
  },
  methods: {
    onClickLeft() {
      this.$router.push({
        path: "/plan"
      })
    },
    chooseStartDate(date) {
      this.startDate = dayjs(date).format("YYYY-MM-DD")
      this.showStartDatePop = false;
    },
    submit() {

      if (this.anonymous) {
        this.name = null
      }else if (this.name.trim().length === 0) {
        Toast.fail("请先输入你的称呼")
        return
      }
      if (this.isPay){
        Toast.loading({forbidClick: true, duration: 0})
        this.commitSms()
      }else {
        this.createOrder()
      }

      // this.showRechargeDialog();
    },
    createOrder(){
      let period = {'period': this.smsCount}
      createPlanOrder(period).then(res =>{
        if (res.data.code === '00000'){
          this.orderId = res.data.data.orderId
          this.pay(res.data.data)
        }
      })
    },
    submitTime(dateTime) {
      this.sendTimeFormat = '每天  ' + dateTime;
      this.sendTime = dateTime
      this.showSendTimePop = false;
    },
    submitSmsCount(value, index) {

      this.smsCount = this.smsCountColumnsData[index];
      this.smsCountFormat = this.smsCountColumns[index];
      this.showSmsCountPop = false;
    },
    submitAddressee(value, index) {

      this.toPeople = value
      this.addresseeType = this.addresseeTypeDataValue[index];
      this.showAddresseeTypePop = false;
    },
    showTimePopup() {
      this.show = true;
    },
    preview() {
      this.previewSms = true;
      this.previewDate = dayjs(new Date()).format("YYYY年MM月DD日 HH:mm")
    },
    commitPlan() {
      let form = {
        name: this.planName,
        phone: this.phone,
        startTime: this.startDate,
        orderId: this.orderId,
        sendTime: this.sendTime,
        planTemplateId: this.id,
        sendMethod: 'sms',
        addresseeType: this.addresseeType,
        sendName: this.name,

      }
      createPlanByOrderId(form)
          .then(res =>{
            Toast.clear()
            if (res.data.code === '00000'){
              this.isPay = false
              Toast.success("创建成功")
            }else if(res.data.code === '00017'){
              this.isPay = false
              this.createOrder()
            }else {
              Toast.fail("创建失败<br/>如成功支付会在3个工作日内退回")
            }
          })
    },
    pay(data) {
      let that = this
      wx.chooseWXPay({
        timestamp: data.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
        nonceStr: data.nonceStr, // 支付签名随机串，不长于 32 位
        package: data.packageValue, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
        signType: data.signType, // 签名方式,必须跟统一下单接口签名方式一致
        paySign: data.paySign, // 支付签名
        success: function (res) {
          if (res.errMsg === "chooseWXPay:ok") {
            //支付成功后跳转的页面
            console.log('支付成功')
            that.isPay = true
            Toast.loading({ forbidClick: true,duration:0})
            that.commitPlan()
          }
        },
        // 支付取消回调函数
        cencel: function (res) {
          that.isPay = false
          console.log('支付取消')
        },
        // 支付失败回调函数
        fail: function (res) {
          that.isPay = false
          console.log('支付失败')
        }
      })
    }
  }
}
</script>

<style scoped>
.van-nav-bar {
  --van-nav-bar-icon-color: #292833;
  --van-nav-bar-text-color: #292833;
  background-color: #f7f7f7;
}

.main {
  height: 100vh;
  background-color: #f7f7f7;
  box-sizing: border-box;
}


.item {
  margin-top: 15px;
}

.form-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
  margin-top: 15px;
  background-color: #f7f7f7;
}

.send-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #f7f7f7;
}

.send-person {
  width: 115px;
  margin-right: 10px;
}

.width-165 {
  width: 165px;
}

.border-8 {
  border-radius: 8px;
}

.anonymous-line {
  min-height: 45px;
}

.name-input {
  width: 150px;
}

.name-input-field {
  background-color: #f7f7f7;
}

.submit-btn {
  align-items: center;
  width: 160px;
}

.center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 15px;
  padding-top: 15px;
  background-color: #f7f7f7;
}

.sms-count-explain {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  align-items: center;
  justify-content: center;
  color: #999999;
  font-size: 12px;
  height: 30px;
  background-color: #f7f7f7;
}

.recharge-pop {
  display: flex;
  flex-direction: column;
}

.recharge-pop-header {
  height: 48px;
  display: flex;
  flex-direction: row;
  padding-left: 15px;
}

.recharge-pop-header-1 {
  line-height: 48px;
  font-size: 16px;
  color: #292833;
}

.recharge-pop-header-2 {
  line-height: 30px;
  height: 30px;
  font-size: 12px;
  margin-left: 10px;
  margin-top: 10px;
  flex: 1;
  color: #9ca3af;
}

.recharge-pop-header-3 {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>