<template>
  <div class="home">
    <router-view style="padding: 0" />

    <van-tabbar  safe-area-inset-bottom fixed route active-color="#333333" inactive-color="#999999">
      <van-tabbar-item replace to="/index" icon="records">写信</van-tabbar-item>
      <van-tabbar-item replace to="/plan" icon="notes-o">计划</van-tabbar-item>
      <van-tabbar-item replace to="/mailbox" icon="envelop-o">信箱</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
// @ is an alias to /src
import {Tabbar , TabbarItem} from 'vant';
import { ref } from 'vue';
export default {
  name: 'Home',
  data() {
    return {
      active1: 0
    }
  },
  setup() {
    const active = ref(0);
    return {
      active,
    };
  },
  created() {
    //由于 vant 标签栏路由模式，无法自动加载页面，所以这里需要初始化
    if (this.$route.path === '/') {
      this.$router.push('/index');
    }
  },
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem
  }
}
</script>
<style scoped>
.home{
  height: 100vh;
  background-color: #f7f7f7;
}
</style>
