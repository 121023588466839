<template>
  <div class="main">
    <van-nav-bar title="管理短信计划"
                 left-arrow
                 @click-left="onClickLeft"/>

    <div>
      <van-cell-group class="item" inset>
        <van-field label="计划名称" v-model="planName" readonly/>
      </van-cell-group>
      <van-cell-group class="item" inset>
        <van-field label="手机号码" readonly v-model="phone"/>
      </van-cell-group>
      <van-cell-group class="item" inset>
        <van-field label="发送周期" readonly v-model="sendCycle"/>
      </van-cell-group>
      <van-cell-group class="item" inset>
        <van-field label="发送时间" readonly v-model="sendTimeFormat"/>
      </van-cell-group>

      <div class="form-item anonymous-line">
        <van-checkbox v-model="anonymous" checked-color="#00D477">
          匿名发送
        </van-checkbox>
        <div class="van-hairline--bottom name-input">
          <van-field v-if="!anonymous" class="name-input-field" v-model="name" readonly/>
        </div>
        <div class="show-sms" @click="showSms">
          查看已发短信
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import {NavBar, Field, CellGroup, Cell, Checkbox, Toast} from 'vant';
import {getPlanInfo} from "../../api/sms";
import dayjs from 'dayjs'

export default {
  name: "Manage",
  components: {
    [NavBar.name]: NavBar,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Checkbox.name]: Checkbox,
    [Toast.name]: Toast,
  },
  data() {
    return {
      planName: '',
      phone: '',
      period: '2022/2/22~2022/2/31(7天)',
      id: '',
      sendCycle: '',
      sendTime: '',
      sendTimeFormat: '',
      name: '',
      anonymous: true,
    }
  },
  created() {
    this.id = this.$route.query.id
    Toast.loading({forbidClick: true, duration: 0})
    getPlanInfo(this.id)
        .then(res => {
          let plan = res.data.data
          this.planName = plan.name
          this.phone = plan.phone;
          this.period = plan.period
          this.sendTime = plan.sendTime
          this.sendTimeFormat = dayjs(plan.sendTime).format("HH:mm")
          this.name = plan.sendName
          this.sendCycle = dayjs(plan.startTime).format("YYYY/MM/DD") + "~"
              + dayjs(plan.endTime).format("YYYY/MM/DD") + "(" + this.period + "天)"
          if (this.name.trim().length === 0) {
            this.anonymous = true
          } else {
            this.anonymous = false
          }
          Toast.clear()
        })
  },
  beforeDestroy() {
    Toast.clear()
  },
  methods: {
    onClickLeft() {
      this.$router.push({
        path: "/plan"
      })
    },
    showSms() {
      this.$router.push({
        path: '/plan/record',
        query: {
          id: this.id
        }
      })
    }
  }
}
</script>

<style scoped>
.van-nav-bar {
  --van-nav-bar-icon-color: #292833;
  --van-nav-bar-text-color: #292833;
  background-color: #f7f7f7;
}

.main {
  height: 100vh;
  background-color: #f7f7f7;
  box-sizing: border-box;
}


.item {
  margin-top: 15px;
}

.form-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
  margin-top: 15px;
  background-color: #f7f7f7;
}


.anonymous-line {
  min-height: 45px;
  box-sizing: border-box;
}

.name-input {
  width: 150px;
}

.name-input-field {
  background-color: #f7f7f7;
}

.show-sms {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #5c678d;
  font-size: 12px;
  height: 45px;
  background-color: #f7f7f7;
}

</style>