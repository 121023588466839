<template>
  <div class="main">
    <van-nav-bar title="短信发送记录"
                 left-arrow
                 safe-area-inset-top
                 fixed
                 style="z-index: 99;"
                 @click-left="onClickLeft"/>

    <div class="content">

      <SmsSendRecordItem v-for="(item, index) in sendList"
          :date="item.sendTime"
          :content="item.content"/>

    </div>

  </div>


</template>

<script>
import {NavBar} from 'vant';
import {getPlanSendList} from "../../api/sms";
import SmsSendRecordItem from "../../components/plan/SmsSendRecordItem";

export default {
  name: "Record",
  components: {
    [NavBar.name]: NavBar,
    SmsSendRecordItem,
  },
  created() {
    this.id = this.$route.query.id;
    getPlanSendList(this.id)
        .then(res => {
          this.sendList = res.data.data
        })
  },
  data() {
    return {
      id: '',
      sendList: []
    }
  },

  methods: {
    onClickLeft() {
      history.back();
    },
  }
}
</script>

<style scoped>
.van-nav-bar {
  --van-nav-bar-icon-color: #292833;
  --van-nav-bar-text-color: #292833;
  background-color: #f7f7f7;
}

.main {
  height: 100vh;
  background-color: #f7f7f7;
  box-sizing: border-box;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 48px 15px 15px;
  box-sizing: border-box;
  background-color: #f7f7f7;
}


</style>