<template>

  <div class="box">
    <div class="avg-price">
      ￥<span class="avg-price-span">{{ avgPrice }}</span>/条
    </div>

    <div class="box-center">
      <div class="sms-num">{{ num }}条短信 <span v-if="tip" class="tag">{{tip}}</span></div>
      <div class="combo-desc">{{note}}</div>
    </div>
    <div class="combo-price">
      ￥{{ price }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ComboRechargeItem",
  props: {
    avgPrice: String,
    num: Number,
    tip: String,
    note: String,
    price: Number,
  }
}
</script>

<style scoped>
.box{
  background: white;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
}
.avg-price{
  background: #f7f7f7;
  border-radius: 10px;
  color: #292833;
  text-align: center;
  line-height: 60px;
  height: 60px;
  width: 100px;
  font-size: 12px;
}
.avg-price-span{
  font-size: 18px;
  font-weight: bold;
}
.box-center{
  padding-left: 15px;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.sms-num{
  font-size: 16px;
  line-height: 30px;
  font-weight: bold;
  color: #292833;
}
.tag{
  padding: 2px;
  border-radius: 5px;
  background-color: #fa6b12;
  color: white;
  font-size: 10px;
}

.combo-desc{
  color: #9ca3af;
  line-height: 20px;
  font-size: 12px;
}
.combo-price{
  background-color: #00D477;
  color: white;
  width: 60px;
  border-radius: 15px;
  line-height: 30px;
  font-weight: bold;
  text-align: center;
  height: 30px;
}

</style>